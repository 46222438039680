<template>
  <div class="about">
    <head-nav></head-nav>
    <about-img></about-img>
    <!-- <div class="about-box">
      <div class="wrap">
        <div class="introduce">
          <h3 class="introduce-title">
            <img
              :src="$t('about.title-1')"
              alt=""
            />
          </h3>
          <div
            class="introduce-content"
            v-html="$t('about.aboutpage.list-content-1')"
          >
          </div>
          <div class="introduce-img">
            <div
              class="introduce-imgs"
              v-for="(item, index) in $t('about.aboutpage.list-1')"
              :key="index"
            >
              <img
                :src="item.img"
                :alt="item.id"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="idea">
      <div class="wrap">
        <h3 class="idea-title">
          <img
            :src="$t('about.title-2')"
            alt=""
          />
        </h3>
        <div
          class="idea-content"
          v-html="$t('about.aboutpage.list-content-2')"
        >
        </div>
        <div class="idea-img">
          <div
            class="idea-imgs"
            v-for="(item, index) in $t('about.aboutpage.list-2')"
            :key="index"
          >
            <img
              :src="item.img"
              :alt="item.id"
            />
          </div>
        </div>
      </div>
    </div> -->
    <div class="certificate patent">
      <div class="wrap">
        <h3 class="certificate-title">
          <img
            :src="$t('about.title.title-2')"
            alt=""
          />
        </h3>
        <div
          v-html="patentLunbo.content"
          class="certificate-content"
        >
        </div>
        <div class="certificate-swiper">
          <swiper
            ref="swiper"
            :options="patentSwiper"
          >
            <swiper-slide
              v-for="(item, index) in patentLists"
              :key="index"
            >
              <img :src="item.image.publicUrl">
              <div
                class="mask"
                @click="handleClone(item)"
              >
                <p class="mask-title">
                  {{item.title}}
                </p>
                <div class="mask-content">
                  <p>{{item.abstract}}</p>
                </div>
              </div>

            </swiper-slide>
          </swiper>
          <div
            v-if="maskLsit"
            class="listEnlargeImg"
          >

            <div class="img">
              <i
                class="iconfont icon-cha"
                @click.stop="handleCloneMask()"
              ></i>
              <img
                :src="lunboinfo.image.publicUrl"
                :alt="lunboinfo.title"
              >
              <p>{{ lunboinfo.title }}</p>
              <p>{{lunboinfo.abstract}}</p>

            </div>

          </div>
          <div
            class="swiper-patent-button-prev swiper-button-prev"
            slot="button-prev"
          ></div>
          <div
            class="swiper-patent-button-next swiper-button-next"
            slot="button-next"
          ></div>
          <div
            class="swiper-patent-pagination swiper-pagination patent-swiper-pagination"
            slot="pagination"
          ></div>
        </div>
      </div>
    </div>
    <div class="certificate">
      <div class="wrap">
        <h3 class="certificate-title">
          <img
            :src="$t('about.title.title-1')"
            alt=""
          />
        </h3>
        <div
          v-html="certificateLunbo.content"
          class="certificate-content"
        >
        </div>
        <div class="certificate-swiper">
          <swiper
            ref="swiper"
            :options="certificateSwiper"
          >
            <swiper-slide
              v-for="(item, index) in certificateLists"
              :key="index"
            >
              <img :src="item.image.publicUrl">
              <div
                class="mask"
                @click="handleClone(item)"
              >
                <p class="mask-title">
                  {{item.title}}
                </p>
                <div class="mask-content">
                  <p>{{item.abstract}}</p>
                </div>
              </div>

            </swiper-slide>
          </swiper>
          <div
            v-if="maskLsit"
            class="listEnlargeImg"
          >

            <div class="img">
              <i
                class="iconfont icon-cha"
                @click.stop="handleCloneMask()"
              ></i>
              <img
                :src="lunboinfo.image.publicUrl"
                :alt="lunboinfo.title"
              >
              <p>{{ lunboinfo.title }}</p>
              <p>{{lunboinfo.abstract}}</p>

            </div>

          </div>
          <div
            class="swiper-certificate-button-prev swiper-button-prev"
            slot="button-prev"
          ></div>
          <div
            class="swiper-certificate-button-next swiper-button-next"
            slot="button-next"
          ></div>
          <div
            class="swiper-certificate-pagination swiper-pagination certificate-swiper-pagination"
            slot="pagination"
          ></div>
        </div>
      </div>
    </div>
    <div>
    </div>

    <footers></footers>
  </div>
</template>

<script>
import gql from 'graphql-tag';

import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

import HeadNav from './head-nav';
import AboutImg from './about-img';
import Footers from './footers';

export default {
  title: 'Change direction',
  components: {
    HeadNav,
    AboutImg,
    Footers,
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  apollo: {
    certificateList: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              certificateList: allQualificationCertificateIntroductions {
                content
                qualificationCertificates {
                  id
                  image {
                    publicUrl
                  }
                  title
                  abstract
                  order
                }
              }
            }
          `;
        }
        return gql`
          query {
            certificateList: allEnQualificationCertificateIntroductions {
              content
              qualificationCertificates {
                id
                image {
                  publicUrl
                }
                title
                abstract
                order
              }
            }
          }
        `;
      },
    },
    patentList: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              patentList: allPatentIntroductions {
                content
                patents {
                  id
                  image {
                    publicUrl
                  }
                  title
                  abstract
                  order
                }
              }
            }
          `;
        }
        return gql`
          query {
            patentList: allPatentIntroductions {
              content
              patents {
                id
                image {
                  publicUrl
                }
                title
                abstract
                order
              }
            }
          }
        `;
      },
    },
  },
  computed: {
    certificateLunbo() {
      if (this.certificateList.length < 1) {
        return {
          content: '',
        };
      }
      return this.certificateList[0];
    },
    patentLunbo() {
      if (this.patentList.length < 1) {
        return {
          content: '',
        };
      }

      return this.patentList[0];
    },

    patentLists() {
      const patentList = this.patentList[0].patents;
      return patentList.sort((a, b) => a.order - b.order);
    },
    certificateLists() {
      const certificateList = this.certificateList[0].qualificationCertificates;
      return certificateList.sort((a, b) => a.order - b.order);
    },
  },

  data() {
    return {
      maskLsit: false,
      lunboinfo: {},
      certificateSwiper: {
        slidesPerView: 5,
        spaceBetween: 26,
        direction: 'horizontal',
        pagination: {
          el: '.swiper-certificate-pagination',
          clickable: true,
          slidesOffsetBefore: 0,
        },
        navigation: {
          nextEl: '.swiper-certificate-button-next',
          prevEl: '.swiper-certificate-button-prev',
        },
        on: {
          resize: () => {
            this.$refs.swiper.$swiper.changeDirection(
              window.innerWidth <= 960 ? 'vertical' : 'horizontal',
            );
          },
        },
      },
      patentSwiper: {
        slidesPerView: 5,
        spaceBetween: 26,
        direction: 'horizontal',
        pagination: {
          el: '.swiper-patent-pagination',
          clickable: true,
          slidesOffsetBefore: 0,
        },
        navigation: {
          nextEl: '.swiper-patent-button-next',
          prevEl: '.swiper-patent-button-prev',
        },
        on: {
          resize: () => {
            this.$refs.swiper.$swiper.changeDirection(
              window.innerWidth <= 960 ? 'vertical' : 'horizontal',
            );
          },
        },
      },
      patentList: [
        {
          patents: [],
        },
      ],
      certificateList: [
        {
          qualificationCertificates: [],
        },
      ],
    };
  },
  methods: {
    handleClone(item) {
      this.lunboinfo = item;

      this.maskLsit = true;
      console.log(this.lunboinfo);
    },
    handleCloneMask() {
      this.maskLsit = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.about {
  // .about-box {
  //   .introduce {
  //     padding: 90px 0 80px 0;

  //     .introduce-title {
  //       text-align: center;
  //       margin-bottom: 30px;
  //     }

  //     .introduce-content {
  //       font-size: 14px;
  //       line-height: 20px;
  //       margin-top: 40px;
  //     }

  //     .introduce-img {
  //       margin-top: 30px;
  //       height: 450px;
  //       position: relative;

  //       .introduce-imgs {
  //         img {
  //           width: 100%;
  //           height: 100%;
  //         }
  //       }

  //       .introduce-imgs:nth-child(1) {
  //         width: 600px;
  //         height: 150px;
  //         position: absolute;
  //         top: 0;
  //         left: 0;
  //       }

  //       .introduce-imgs:nth-child(2) {
  //         width: 300px;
  //         height: 300px;
  //         position: absolute;
  //         top: 0;
  //         left: 600px;
  //       }

  //       .introduce-imgs:nth-child(3) {
  //         width: 300px;
  //         height: 150px;
  //         position: absolute;
  //         top: 0;
  //         left: 900px;
  //       }

  //       .introduce-imgs:nth-child(4) {
  //         width: 300px;
  //         height: 300px;
  //         position: absolute;
  //         top: 150px;
  //         left: 0;
  //       }

  //       .introduce-imgs:nth-child(5) {
  //         width: 300px;
  //         height: 150px;
  //         position: absolute;
  //         top: 150px;
  //         left: 300px;
  //       }

  //       .introduce-imgs:nth-child(6) {
  //         width: 150px;
  //         height: 150px;
  //         position: absolute;
  //         top: 300px;
  //         left: 300px;
  //       }

  //       .introduce-imgs:nth-child(7) {
  //         position: absolute;
  //         left: 450px;
  //         top: 300px;
  //         width: 450px;
  //         height: 150px;
  //       }

  //       .introduce-imgs:nth-child(8) {
  //         position: absolute;
  //         left: 900px;
  //         top: 150px;
  //         width: 300px;
  //         height: 300px;
  //       }
  //     }
  //   }
  // }

  // .idea {
  //   width: 100%;
  //   height: 770px;
  //   background: #eee;

  //   .wrap {
  //     padding: 90px 0 30px 0;

  //     .idea-title {
  //       text-align: center;
  //     }

  //     .idea-content {
  //       text-align: center;
  //       margin-top: 40px;
  //     }

  //     .idea-img {
  //       margin-top: 40px;
  //       position: relative;

  //       .idea-imgs {
  //         img {
  //           width: 100%;
  //           height: 100%;
  //         }
  //       }

  //       .idea-imgs:nth-child(1) {
  //         width: 150px;
  //         height: 150px;
  //         position: absolute;
  //         top: 0;
  //         left: 0;
  //       }

  //       .idea-imgs:nth-child(2) {
  //         width: 450px;
  //         height: 150px;
  //         position: absolute;
  //         top: 0;
  //         left: 150px;
  //       }

  //       .idea-imgs:nth-child(3) {
  //         width: 450px;
  //         height: 300px;
  //         position: absolute;
  //         top: 0;
  //         left: 600px;
  //       }

  //       .idea-imgs:nth-child(4) {
  //         width: 150px;
  //         height: 220px;
  //         position: absolute;
  //         top: 0;
  //         left: 1050px;
  //       }

  //       .idea-imgs:nth-child(5) {
  //         width: 600px;
  //         height: 150px;
  //         position: absolute;
  //         top: 150px;
  //         left: 0;
  //       }

  //       .idea-imgs:nth-child(6) {
  //         width: 450px;
  //         height: 150px;
  //         position: absolute;
  //         top: 300px;
  //         left: 0;
  //       }

  //       .idea-imgs:nth-child(7) {
  //         width: 150px;
  //         height: 150px;
  //         position: absolute;
  //         top: 300px;
  //         left: 450px;
  //       }

  //       .idea-imgs:nth-child(8) {
  //         width: 450px;
  //         height: 150px;
  //         position: absolute;
  //         top: 300px;
  //         left: 600px;
  //       }

  //       .idea-imgs:nth-child(9) {
  //         width: 150px;
  //         height: 230px;
  //         position: absolute;
  //         top: 220px;
  //         left: 1050px;
  //       }
  //     }
  //   }
  // }

  .certificate {
    width: 100%;
    height: 660px;
    background: #eee;

    .wrap {
      padding-top: 90px;

      .certificate-title {
        text-align: center;
      }

      .certificate-content {
        margin-top: 40px;
        text-align: center;
        color: #555;
      }

      .certificate-swiper {
        position: relative;
        margin-top: 30px;
        cursor: pointer;

        .swiper-container {
          .swiper-wrapper {
            .swiper-slide {
              width: 220px !important;
              height: 280px !important;
              position: relative;
              &:hover {
                .mask {
                  transition: all 1000ms;
                  -webkit-transition: 5s;
                  -webkit-transition: max-height 5s ease-in-out;
                  -moz-transition: max-height 5s ease-in-out;
                  -o-transition: max-height 5s ease-in-out;
                  transition: max-height 5s ease-in-out;
                  height: 280px;

                  .mask-title {
                    display: none;
                  }

                  .mask-content {
                    display: block;
                    margin: 120px auto 0 auto;
                    width: 120px;
                    font-size: 16px;
                    line-height: 20px;
                  }
                }
              }

              img {
                width: 100%;
                height: 100%;
              }

              .mask {
                position: absolute;
                bottom: 0;
                left: 0;
                height: 60px;
                width: 100%;
                background: #000;
                opacity: 0.7;
                text-align: center;
                z-index: 9;
                color: #fff;

                .mask-title {
                  margin-top: 24px;
                }

                .mask-content {
                  display: none;
                }
              }
            }
          }
        }

        .swiper-button-prev,
        .swiper-container-rtl .swiper-button-next {
          left: -44px;
          color: #fec314;
          &::after {
            font-size: 40px;
          }
          outline: none !important;
        }

        .swiper-button-next,
        .swiper-container-rtl .swiper-button-prev {
          right: -44px;
          color: #fec314;
          border: none;
          &::after {
            font-size: 40px;
          }
          outline: none !important;
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
          color: #818181;
        }

        ::v-deep .patent-swiper-pagination {
          left: 45%;
        }
        ::v-deep .certificate-swiper-pagination {
          left: 33%;
        }

        ::v-deep .swiper-pagination {
          top: 300px;

          .swiper-pagination-bullet {
            margin: 0 6px;
            outline: none;
          }
          .swiper-pagination-bullet-active {
            background: #ffbf00;
          }
        }
      }

      .listEnlargeImg {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 999;
        text-align: center;
        img {
          position: absolute;
          top: 52%;
          left: 50%;
          transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          -khtml-transform: translate(-50%, -50%);
          z-index: 20;
          height: 600px;
          border: 10px solid #fff;
        }
        .icon-cha {
          position: absolute;
          top: 10%;
          right: 10%;
          font-size: 30px;
          color: #fdfdfd;
        }
      }
    }
  }

  .patent {
    background: #fff !important;
  }
}

@media screen and (max-width: 1200px) {
  .about .idea {
    width: 1200px;
  }

  .home .home-news .wrap .home-swiper .swiper-container .swiper-wrapper {
    width: 1200px !important;
    display: flex !important;
    flex: 1 !important;
    justify-content: space-around !important;
  }

  .home .home-news .wrap .home-swiper .swiper-slide {
    height: 100% !important;
  }

  .about .certificate .wrap .certificate-swiper .swiper-button-next,
  .about .certificate .wrap .certificate-swiper .swiper-container-rtl .swiper-button-prev {
    display: none !important;
  }
}
</style>
